"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupportedAlgorithms = void 0;
var SupportedAlgorithms;
(function (SupportedAlgorithms) {
    SupportedAlgorithms["HS256"] = "HS256";
    SupportedAlgorithms["HS384"] = "HS384";
    SupportedAlgorithms["HS512"] = "HS512";
    SupportedAlgorithms["NONE"] = "none";
})(SupportedAlgorithms = exports.SupportedAlgorithms || (exports.SupportedAlgorithms = {}));
