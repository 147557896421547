"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var Errors = __importStar(require("./errors"));
var claims_1 = require("../types/claims");
var claims = [
    claims_1.Claims.EXP,
    claims_1.Claims.NBF,
    claims_1.Claims.IAT,
    claims_1.Claims.SUB,
    claims_1.Claims.ISS,
    claims_1.Claims.AUD,
];
var parseToNumber = function (value) {
    return value ? parseInt(value.toString()) || undefined : undefined;
};
var Verifier = /** @class */ (function () {
    function Verifier(body, claim, options) {
        if (options === void 0) { options = {}; }
        this.body = body;
        this.options = options;
        this.time = Date.parse(new Date().toString()) / 1000;
        this.timeSkew = options.timeSkew || 0;
        var methodName = "verify_".concat(claim);
        if (this[methodName]) {
            this[methodName]();
        }
    }
    Verifier.verifyAll = function (body, options) {
        var _this = this;
        claims.forEach(function (claim) {
            new _this(body, claim, options);
        });
    };
    Verifier.prototype.verify_exp = function () {
        var exp = parseToNumber(this.body.exp);
        if (!exp) {
            return;
        }
        if (exp < this.time - this.timeSkew) {
            throw new Errors.TokenExpired();
        }
    };
    Verifier.prototype.verify_nbf = function () {
        var nbf = parseToNumber(this.body.nbf);
        if (!nbf) {
            return;
        }
        if (nbf > this.time + this.timeSkew) {
            throw new Errors.ImmatureSignature();
        }
    };
    Verifier.prototype.verify_iat = function () {
        var iat = parseToNumber(this.body.iat);
        if (!this.body.iat || !iat) {
            return;
        }
        if (iat > this.time + this.timeSkew) {
            throw new Errors.IssuedAtInvalid();
        }
    };
    Verifier.prototype.verify_sub = function () {
        if (!this.options.sub) {
            return;
        }
        if (this.body.sub !== this.options.sub) {
            throw new Errors.InvalidSubject();
        }
    };
    Verifier.prototype.verify_iss = function () {
        if (!this.options.iss) {
            return;
        }
        if (this.body.iss !== this.options.iss) {
            throw new Errors.InvalidIssuer();
        }
    };
    Verifier.prototype.verify_aud = function () {
        if (!this.options.aud) {
            return;
        }
        if (this.body.aud !== this.options.aud) {
            throw new Errors.InvalidAudience();
        }
    };
    return Verifier;
}());
exports.default = Verifier;
