"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Claims = void 0;
var Claims;
(function (Claims) {
    Claims["EXP"] = "exp";
    Claims["NBF"] = "nbf";
    Claims["IAT"] = "iat";
    Claims["SUB"] = "sub";
    Claims["ISS"] = "iss";
    Claims["AUD"] = "aud";
})(Claims = exports.Claims || (exports.Claims = {}));
